body {
  margin: 0;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-overflow-scrolling: touch;
  touch-action: manipulation;
}

html,
body,
#root {
  height: 100%;
}

/*  */
/* inputs */
/*  */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  transition: background-color 5000s ease-in-out 0s;
}

/*  */
/* buttons, inputs */
/*  */
a,
div,
button:focus,
input,
.w-button {
  outline: 0;
}

/*  */
/* Toast Styles */
/*  */
.Toastify__toast-container {
  z-index: 999999;
}

.toast {
  min-height: 50px !important;
  background-color: #442c79 !important;
  color: #fff !important;
}

.toast.Toastify__toast--warning {
  background-color: #db0c41 !important;
}

/* table styles */
.MuiGrid-container {
  align-items: center;
}

.MuiTable-root thead tr th span {
  align-items: center;
  user-select: none;
}

.MuiTableRow-root,
.MuiTableRow-root:hover {
  cursor: default !important;
}

/* routes table styles */
.arrows {
  color: #76b900;
}

.arrows:hover,
.edit:hover,
.remove:hover {
  color: white;
  background-color: #76b900;
  cursor: pointer;
  border-radius: 10%;
}

/* react datapicker on dashboard styles */
.react-datepicker-wrapper {
  width: fit-content !important;
}

.react-datepicker__input-container input {
  padding: 5px;
  text-align: center;
}

.react-datepicker-popper {
  z-index: 101 !important;
}

.react-datepicker-wrapper .react-datepicker__input-container input {
  border-color: transparent;
  border: 0.5px solid #e0e0e0;
  border-radius: 5px;
}

.react-datepicker-wrapper .react-datepicker__input-container input:hover {
  cursor: pointer;
}
